













import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import { SimpleUser, Submission } from "@/core/models";

@Component
export default class SubmissionFooter extends Vue {
  @Getter("seminars/isOwner") isOwner!: boolean;
  @Getter("seminars/users") users!: SimpleUser[];

  user(id: string) {
    const one = this.users.find(x => x.id === id);
    if (!one) return "You";
    return `${one.username} (${one.email})`;
  }

  @Prop({ default: () => undefined }) submission?: Submission;
  @Prop({ default: () => true }) marginTop?: boolean;
}
